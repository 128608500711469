import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CourseItem, CourseResponse } from "../../interfaces/courses";
import { fetchDoceboOauthToken } from "../../utils/doceboAuth";

const initialState = {
  loading: false,
  courses: [] as CourseItem[],
  courseResp: {} as CourseResponse,
};

interface QueryProps {
  page_size?: number;
  page?: number;
  sort_attr?: string;
  sort_order?: "asc" | "desc";
  filter_value?: string;
  role?: string;
}

export const getDoceboCourse = createAsyncThunk(
  "courses/getDoceboCourse",
  async (props: QueryProps, { getState, rejectWithValue }) => {
    try {
      const url = `https://devorbicollege.orbico.com/learn/v1/courses`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 1ace4417ca63bf2dab9a92a9192167603ccf935d",
        },
      }).then((res) => res.json());

      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Authorization failed"
      );
    }
  }
);

export const getCourses = createAsyncThunk(
  "courses/getCourses",
  async (props: QueryProps, { getState, rejectWithValue }) => {
    try {
      let query = "?";

      for (const [key, value] of Object.entries(props)) {
        query += `${key}=${value}&`;
      }

      const url = `${process.env.REACT_APP_BASE_API_URL}/courses${query}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());

      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Authorization failed"
      );
    }
  }
);

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload?.data || [];
        state.courseResp = action.payload;
      })
      .addCase(getCourses.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDoceboCourse.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDoceboCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload?.data || [];
        state.courseResp = action.payload;
      })
      .addCase(getDoceboCourse.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default coursesSlice.reducer;
