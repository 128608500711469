import { useTranslation } from "react-i18next";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";

function HomeButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-row items-center justify-start gap-2 cursor-pointer hover:text-[#961e38] transition-all"
      onClick={() => navigate("/")}
    >
      <GoHomeFill />
      {t("Home")}
    </div>
  );
}

export default HomeButton;
