import React from "react";
import { useTranslation } from "react-i18next";

function SaveConfirmModal({
  closeModal,
  handleSave,
  loading,
  msg,
}: {
  closeModal: () => void;
  handleSave: () => void;
  loading?: boolean;
  msg: string;
}) {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-5 rounded-md shadow-lg flex flex-col justify-between items-end w-[500px] h-[200px]">
        <div className="flex flex-col items-start justify-start gap-5 w-full">
          <h2 className="text-lg font-semibold text-red-600">
            {t("Confirm Action")}
          </h2>
          <p className="mt-2">{t(msg)}</p>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={handleSave}
          >
            {loading ? `${t("Saving")}...` : t("Save")}
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded"
            onClick={closeModal}
          >
            {t("Close")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SaveConfirmModal;
