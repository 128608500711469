import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ManagerCourse } from "../../interfaces/manager.application";
import { getQueryString } from "../../utils/helper";

const initialState = {
  loading: false,
  managerCourses: [] as ManagerCourse[],
  selectedCourseApplication: {} as ManagerCourse,
  updateLoading: false,
};

export const getManagerApplications = createAsyncThunk(
  "managerApplications/getManagerApplications",
  async (_, { getState, rejectWithValue }) => {
    const query = getQueryString({
      user_id: sessionStorage.getItem("userId"),
      role: "manager",
      status: "MANAGER_APPROVAL",
    });

    const url = `${process.env.REACT_APP_BASE_API_URL}/applications${query}`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());

    return response;
  }
);

export const updateManagerApplicationStatus = createAsyncThunk(
  "managerApplications/updateManagerApplicationStatus",
  async (props: {
    status: string;
    courseId: string | number;
    userId: string | number;
    travel: boolean | null;
  }) => {
    const query = getQueryString({
      user_id: props.userId,
      role: "manager",
      course_id: props.courseId,
    });

    const url = `${process.env.REACT_APP_BASE_API_URL}/applications/${query}`;

    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        application_status: props.status,
        travel: props.travel,
      }),
    }).then((res) => res.json());

    return response.data;
  }
);

export const managerApplicationsSlice = createSlice({
  name: "managerApplications",
  initialState: initialState,
  reducers: {
    setSelectedCourseApplication: (state, action) => {
      state.selectedCourseApplication = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManagerApplications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getManagerApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.managerCourses = action.payload.data;
      })
      .addCase(getManagerApplications.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateManagerApplicationStatus.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateManagerApplicationStatus.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(updateManagerApplicationStatus.rejected, (state) => {
        state.updateLoading = false;
      });
  },
});

export default managerApplicationsSlice.reducer;

export const { setSelectedCourseApplication } =
  managerApplicationsSlice.actions;
