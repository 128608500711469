import React, { useEffect, useId, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import BackButton from "../../../components/BackButton";
import Header from "../../../components/Header";
import {
  getManagerApplications,
  setSelectedCourseApplication,
  updateManagerApplicationStatus,
} from "../../../redux/slices/manager.applications.slice";
import SpinnerLoader from "../../../components/SpinnerLoader";
import SaveConfirmModal from "../../../components/modal/SaveConfirmModal";
import { FaEllipsisH } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const STATUS_MAP: any = {
  REJECTED_BY_MANAGER: {
    label: "Denied",
    color: "bg-red-500 text-white",
  },
  APPROVED_BY_MANAGER: {
    label: "Accepted",
    color: "bg-green-500 text-white",
  },
};

function ManagerApplications() {
  const { selectedCourseApplication, updateLoading, managerCourses, loading } =
    useSelector((state: RootState) => state.managerApplications);

  const [confirmationMenu, setConfirmationMenu] = useState<{
    action: string;
    item: any;
    isAllow?: boolean;
  } | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null); // Reference for Menu Modal
  const confirmationRef = useRef<HTMLDivElement | null>(null); // Reference for Confirmation Modal
  const [menuOpen, setMenuOpen] = useState<number | null>(null); // Track which menu is open

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    applications,
    course_id,
    course_name,
    course_type,
    language,
    creation_date,
  } = selectedCourseApplication;

  // Close modals when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      console.log("Event target:", event.target);
      console.log("Menu ref:", menuRef.current);
      console.log("Confirmation ref:", confirmationRef.current);

      if (
        (menuRef.current && !menuRef.current.contains(event.target as Node)) ||
        (confirmationRef.current &&
          !confirmationRef.current.contains(event.target as Node))
      ) {
        setMenuOpen(null); // Close the menu modal
        setConfirmationMenu(null); // Close the confirmation modal
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleConfirmation = async (confirm: boolean) => {
    if (confirm && confirmationMenu) {
      const { action, isAllow, item } = confirmationMenu;

      const dataToSend: any = {
        courseId: course_id,
        userId: item.user_id,
      };
      // Example: Call Redux API based on action
      if (action === "APPROVED_BY_MANAGER" && isAllow === true) {
        dataToSend.status = "APPROVED_BY_MANAGER";
        dataToSend.travel = true;
      } else if (action === "APPROVED_BY_MANAGER" && isAllow === false) {
        dataToSend.status = "APPROVED_BY_MANAGER";
        dataToSend.travel = false;
      } else if (action === "REJECTED_BY_MANAGER") {
        dataToSend.status = "REJECTED_BY_MANAGER";
      }

      setConfirmationMenu(null);
      await dispatch(updateManagerApplicationStatus(dataToSend));

      // Step 2: Re-fetch the updated list of manager applications
      const response = await dispatch(getManagerApplications());

      // Step 3: Update the selected course application after fetching
      if (response.payload) {
        const updatedCourse = response.payload.data.find(
          (course: any) => course.course_id === course_id
        );

        if (updatedCourse) {
          dispatch(setSelectedCourseApplication(updatedCourse));
        }
      }
    } else {
      setConfirmationMenu(null);
    }
  };

  const handleOptionSelect = (action: string, isAllow: boolean, item: any) => {
    setMenuOpen(null); // Close menu
    setConfirmationMenu({ action, isAllow, item }); // Open confirmation menu
  };

  const handleMenuClick = (index: number) => {
    setMenuOpen(menuOpen === index ? null : index); // Toggle menu open/close
  };
  return (
    <div className="flex flex-col items-start gap-8">
      <BackButton />
      <Header />
      <div className="flex flex-row w-full justify-between items-center group cursor-pointer">
        <div className="flex flex-col w-full">
          <p className="text-2xl font-semibold text-[#961e38]">{course_name}</p>
          <div className="flex items-center justify-start gap-5 pt-2">
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">{t("Type")}:</span>
              <span>{course_type}</span>
            </p>
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">{t("Lang")}:</span>
              <span>{language}</span>
            </p>
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">{t("Creation Date")}:</span>
              <span>{creation_date || "N/A"}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full h-full">
        <table className="w-full border-collapse">
          <thead className="bg-[#961e38] w-full text-white rounded-t-xl">
            <tr>
              <th className="py-2 px-4 text-left">{t("Users")}</th>
              <th className="py-2 px-4 text-right">{t("Status")}</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={2} className="py-2 px-4 text-center">
                  <SpinnerLoader />
                </td>
              </tr>
            ) : applications.length > 0 ? (
              applications.map((application, i) => {
                const { application_status, user_id, user_name } = application;
                return (
                  <tr key={user_id}>
                    <td className="border-b border-[#c6c6c6] py-2 px-4 text-left">
                      {user_name}
                    </td>
                    <td
                      className={`border-b border-[#c6c6c6] py-2 px-4 text-right `}
                    >
                      {application_status === "APPLIED" ? (
                        <div className="flex items-center gap-3">
                          <button
                            onClick={() => {
                              setMenuOpen(null); // Close menu
                              setConfirmationMenu(null);
                              handleMenuClick(i);
                            }}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                            title="More options"
                          >
                            <FaEllipsisH size={20} />
                          </button>
                        </div>
                      ) : (
                        <div
                          className={`flex items-center justify-center mt-2 p-3 ${
                            STATUS_MAP[application_status]?.color ||
                            "bg-red-500 text-white"
                          }`}
                        >
                          {STATUS_MAP[application_status]?.label || "N/A"}
                        </div>
                      )}

                      {/* Conditional Menu */}
                      {menuOpen === i && (
                        <div
                          ref={menuRef} // Attach the ref to the menu modal
                          className="absolute z-10 bg-white shadow-md border rounded"
                          style={{ right: "18rem" }}
                        >
                          <ul>
                            {
                              <li
                                onClick={() =>
                                  handleOptionSelect(
                                    "APPROVED_BY_MANAGER",
                                    true,
                                    application
                                  )
                                }
                                className="flex justify-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              >
                                {t("Approve For Travel")}
                              </li>
                            }
                            {
                              <li
                                onClick={() =>
                                  handleOptionSelect(
                                    "APPROVED_BY_MANAGER",
                                    false,
                                    application
                                  )
                                }
                                className="flex justify-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              >
                                {t("Approve For No Travel")}
                              </li>
                            }
                            {
                              <li
                                onClick={() =>
                                  handleOptionSelect(
                                    "REJECTED_BY_MANAGER",
                                    false,
                                    application
                                  )
                                }
                                className="flex justify-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              >
                                {t("Deny")}
                              </li>
                            }
                          </ul>
                        </div>
                      )}
                      {/* Confirmation Menu */}
                      {confirmationMenu &&
                        confirmationMenu.item === application && (
                          <div
                            ref={confirmationRef}
                            className="absolute z-20 bg-white shadow-md border rounded"
                            style={{ right: "22rem" }}
                          >
                            <p className="px-4 py-2">{t("Are you sure")}?</p>
                            <div className="flex justify-around px-4 py-2">
                              <button
                                onClick={() => handleConfirmation(true)}
                                className="text-green-500 hover:underline"
                              >
                                {t("Yes")}
                              </button>

                              <button
                                onClick={() => handleConfirmation(false)}
                                className="text-red-500 hover:underline"
                              >
                                {t("No")}
                              </button>
                            </div>
                          </div>
                        )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={2}>
                  <p className="w-full text-center py-5">
                    {t("No Data Available")}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ManagerApplications;

{
  /* <tfoot className="bg-[#961e38]">
            <tr>
              <td colSpan={2} className="p-5 text-white rounded-b-xl">
                <div className="flex items-center justify-between rounded-b-xl">
                  <div className="flex items-center gap-2">
                    <p className="text-sm">Min Seats</p>
                    <input
                      className="w-12 text-center bg-white text-black"
                      value={minMaxEnroll.min}
                      onChange={(e) => handleMinChange(Number(e.target.value))}
                      disabled={sessionStatus !== "AVAILABLE"}
                    />
                    <p className="text-sm">Max Seats</p>
                    <input
                      className="w-12 text-center bg-white text-black"
                      value={minMaxEnroll.max}
                      onChange={(e) => handleMaxChange(Number(e.target.value))}
                      disabled={sessionStatus !== "AVAILABLE"}
                    />
                  </div>
                  {sessionStatus === "AVAILABLE" && (
                    <button
                      className="py-1.5 px-5 border border-white"
                      onClick={handleMinMaxSeatsSave}
                      disabled={updateLoading}
                    >
                      {updateLoading ? "Saving..." : "Save"}
                    </button>
                  )}
                </div>
              </td>
            </tr>
          </tfoot> */
}
