import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../utils/interceptor";
import { getQueryString } from "../../utils/helper";

const initialState = {
  loading: false,
  isAuthorized: false,
  message: "",
  userRoles: [] as string[],
};

export const getAuth = createAsyncThunk(
  "auth/getAuth",
  async (_, { getState, rejectWithValue }) => {
    try {
      const url = window.location.href;
      const params: any = new URL(url).searchParams;

      // Extract query parameters
      const queryParameters: any = {};
      for (const [key, value] of params.entries()) {
        queryParameters[key] = value;
      }

      // Retrieve the required parameters for authorization
      const userId = parseInt(queryParameters.user_id);
      const username = encodeURIComponent(queryParameters.username);
      const doceboHash = queryParameters.hash;
      const authCode = queryParameters.auth_code;
      const courseId = queryParameters.course_id;
      const courseCode = queryParameters.course_code;

      sessionStorage.setItem("username", username.toString());

      const body = {
        user_id: userId,
        username: username,
        docebo_hash: doceboHash,
        auth_code: authCode,
        course_id: courseId,
        course_code: courseCode,
      };

      const response = await API.post("authorization", body);

      if (response.status !== 200) {
        throw rejectWithValue("Authorization failed");
      }

      sessionStorage.setItem("userId", userId.toString());

      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Authorization failed"
      );
    }
  }
);

export const getUserRoles = createAsyncThunk(
  "auth/getUserRoles",
  async (_, { getState, rejectWithValue }) => {
    const query = getQueryString({
      user_id: sessionStorage.getItem("userId"),
    });

    const url = `${process.env.REACT_APP_BASE_API_URL}/users${query}`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());

    sessionStorage.setItem("docebo_accessToken", response.docebo_token);
    return response.data;
  }

  // Extract query parameters
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuth.pending, (state) => {
        state.loading = true;
        state.message = "";
      })
      .addCase(getAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthorized = true;
        state.message =
          (action.payload.message as string) || "Authorization successful";
      })
      .addCase(getAuth.rejected, (state, action) => {
        state.loading = false;
        state.isAuthorized = false;
        state.message = (action.payload as string) || "Authorization failed";
      })
      .addCase(getUserRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRoles.fulfilled, (state, { payload }) => {
        state.userRoles = payload?.roles;
        state.loading = false;
        if (payload.username)
          sessionStorage.setItem("username", payload?.username?.toString());
      })
      .addCase(getUserRoles.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default authSlice.reducer;
