import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import SpinnerLoader from "../../../../components/SpinnerLoader";
import {
  getLearnerAvailableApplications,
  getLearnerCourseSessions,
  setSelectedCourse,
  updateLearnerApplicationStatus,
} from "../../../../redux/slices/learner.applications.slice";
import { RootState, useAppDispatch } from "../../../../redux/store";
import SaveConfirmModal from "../../../../components/modal/SaveConfirmModal";
import EventDetailsModal from "../../../Sessions/components/EventDetailsModal";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../../components/LanguageSelector";

function CourseSessions() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { availableCourseSessions, loading, selectedCourse, updateLoading } =
    useSelector((state: RootState) => state.learnerApplications);

  const [modalData, setModalData] = useState<any[]>([]); // Modal data for events
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const handleApply = async () => {
    const payload = {
      userId: sessionStorage.getItem("userId") || "",
      courseId: selectedCourse?.course_id,
      status: "APPLIED",
    };

    await dispatch(updateLearnerApplicationStatus(payload));

    setIsSaveModalOpen(false);
    navigate("/learner/applied-trainings");
  };

  useEffect(() => {
    const id = pathname.split("/")[3];

    if (id) {
      dispatch(getLearnerCourseSessions({ course_id: id, role: "learner" }));
    }
  }, [pathname, dispatch]);
  // Parse and set modal data for events
  const handleOpenModal = (events: string) => {
    // Step 1: Split by "," only outside of "({ })"
    const eventBlocks = events.split(/(?<=\)}),/); // Match ", " only outside the "({ })"
    console.log(eventBlocks);
    const parsedEvents = eventBlocks.map((block) => {
      // Step 2: Split by "({"
      const [eventName, detailsBlock] = block.split("{(");

      // Step 3: Remove closing ")}" and split details inside "({ })" by ","
      const details = detailsBlock
        ?.replace(")}", "")
        ?.split(",")
        .map((item) => item.trim());

      // Step 4: Map details to fields
      const [date, time, mode, location, classroom, ...instructorsBlock] =
        details || [];

      console.log(instructorsBlock);
      // Step 5: Clean instructors
      // Step 5: Extract and clean instructors
      const instructors = instructorsBlock
        .join(", ") // Join remaining parts to recreate full instructors string
        .replace("Instructors:", "") // Remove the "Instructors:" prefix
        .trim();

      return {
        eventName: eventName?.trim(),
        date: date || null,
        time: time || null,
        mode: mode || null,
        location: location || null,
        classroom: classroom || null,
        instructors: instructors || null,
      };
    });

    setModalData(parsedEvents);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="flex flex-col items-start justify-start gap-10">
      <BackButton />
      <div className="flex flex-row w-full justify-between items-center group cursor-pointer">
        <div className="flex flex-col w-full">
          <p className="text-2xl font-semibold text-[#961e38]">
            {selectedCourse?.course_name}
          </p>
          <div className="flex items-center justify-start gap-5 pt-2">
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">{t("Type")}:</span>
              <span>{selectedCourse?.course_type}</span>
            </p>
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">{t("Lang")}:</span>
              <span>{selectedCourse?.language}</span>
            </p>
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">{t("Creation Date")}:</span>
              <span>{selectedCourse?.creation_date}</span>
            </p>
          </div>
        </div>
        <LanguageSelector />
      </div>
      <button
        className="py-1.5 px-5 text-white bg-[#961e38] uppercase disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={updateLoading || loading} //availableCourseSessions.length === 0
        onClick={() => setIsSaveModalOpen(true)}
      >
        {t("Apply and find me a seat")}
      </button>
      <div className="w-full h-full">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                {t("Session Name")}
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                {t("Start Date")}
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                {t("End Date")}
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                {t("Events")}
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                {t("Instructors")}
              </th>
              {/* <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Session Status
              </th> */}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} className="text-center">
                  <div className="flex items-center w-full justify-center">
                    <SpinnerLoader />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {availableCourseSessions.length > 0 ? (
                  <>
                    {availableCourseSessions?.map((item, i) => (
                      <tr key={i}>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.session_name}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.date_start}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.date_end}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item.events ? (
                            <span
                              className="text-blue-500 cursor-pointer underline"
                              onClick={() => handleOpenModal(item.events)}
                            >
                              {t("Open")}
                            </span>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item.instructors
                            .split(",")
                            .map((instructor: any, index: number) => (
                              <span key={index}>
                                {`${instructor.trim()},`}
                                <br />
                              </span>
                            ))}
                        </td>
                        {/* <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item.session_status}
                        </td> */}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr className="pt-5">
                    <td colSpan={5} className="text-center">
                      {t("No Session Data found for")}{" "}
                      {selectedCourse.course_name}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
          <EventDetailsModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            modalData={modalData}
          />
        </table>
      </div>
      {isSaveModalOpen && (
        <SaveConfirmModal
          closeModal={() => setIsSaveModalOpen(false)}
          handleSave={handleApply}
          loading={updateLoading}
          msg="Are you sure you want to apply for this course?"
        />
      )}
    </div>
  );
}

export default CourseSessions;
