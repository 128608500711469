import React, { useEffect, useState } from "react";
import BackButton from "../../../components/BackButton";
import Header from "../../../components/Header";
import TabNavbar from "../../../components/Tabbar";
import { ADMIN_TABS } from "../../../utils/TabbarData";
import SearchWithFilter from "../../../components/SearchWithFilter";
import { RootState, useAppDispatch } from "../../../redux/store";
import { getCourses } from "../../../redux/slices/couses.slice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GoSearch } from "react-icons/go";
import SpinnerLoader from "../../../components/SpinnerLoader";

const TrainingCost = () => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { t } = useTranslation();

  const { courseResp, courses, loading } = useSelector(
    (state: RootState) => state.course
  );

  // Handle page change
  const handlePageChange = (page: number) => setCurrentPage(page);

  // Handle search input
  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    // Fetch courses from the API
    dispatch(
      getCourses({
        page_size: itemsPerPage,
        page: currentPage,
        filter_value: searchTerm, // Pass search term to API
        role: "admin",
      })
    );
  }, [currentPage, searchTerm, dispatch, itemsPerPage]);

  // Rendered data (from API)
  const totalPages = Math.ceil(
    (courseResp?.total_item_count || 0) / itemsPerPage
  );

  return (
    <div className="flex flex-col items-start gap-8">
      <BackButton />
      <Header />
      <TabNavbar id="seat-allocation" tabs={ADMIN_TABS} selectedTabId={2} />
      <div className="w-full">
        <SearchWithFilter
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          disabled={loading}
        />
      </div>
      <div className="w-full h-full">
        <table className="w-full border-collapse">
          <thead className="bg-[#961e38] w-full text-white rounded-t-xl">
            {/* <tr>
              <th colSpan={3} className="py-2 text-left rounded-t-xl">
                <div className="px-3 py-2 flex items-center gap-2 w-full">
                  <GoSearch size={18} />
                  <input
                    type="text"
                    placeholder={t("Search")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    disabled={loading}
                    className="w-full p-2 border-b-2 border-white bg-inherit focus:border-white focus:outline-none"
                  />
                </div>
              </th>
            </tr> */}
            <tr>
              <th className="py-2 px-4 text-left">{t("Course Name")}</th>
              <th className="py-2 px-2 text-right">
                {t("Classroom Training Cost")}
              </th>
              <th className="py-2 px-2 text-right">
                {t("Online Training Cost")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td
                  colSpan={3}
                  className="flex items-center justify-center py-5"
                >
                  <SpinnerLoader />
                </td>
              </tr>
            ) : (
              <>
                {courses.length > 0 ? (
                  courses.map((course: any, i) => (
                    <tr key={i}>
                      <td className="border-b border-[#c6c6c6] py-2 px-4 text-left">
                        {course.course_name}
                      </td>
                      <td className="border-b border-[#c6c6c6] py-2 px-4 text-center">
                        <input
                          className="w-[50px] border border-[#909090] text-center"
                          value={
                            course.classroom_training_cost === "NA"
                              ? "-"
                              : course.classroom_training_cost
                          }
                          disabled={true}
                        />
                      </td>
                      <td className="border-b border-[#c6c6c6] py-2 px-4 text-center">
                        <input
                          className="w-[50px] border border-[#909090] text-center"
                          value={
                            course.online_training_cost === "NA"
                              ? "-"
                              : course.online_training_cost
                          }
                          disabled={true}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>
                      <p className="w-full text-center py-5">
                        {t("No Data Available")}
                      </p>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>

        {courses?.length > 0 && !loading && (
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-center items-center gap-4 mt-4">
              {/* Previous Button */}
              <button
                className={`px-4 py-2 border rounded ${
                  currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {t("Previous")}
              </button>

              {/* Dynamic Page Buttons */}
              {Array.from({ length: Math.min(totalPages, 4) }).map(
                (_, index) => {
                  const startPage = Math.max(
                    1,
                    Math.min(currentPage - 1, totalPages - 3)
                  );
                  const pageNumber = startPage + index;

                  return (
                    <button
                      key={pageNumber}
                      className={`px-4 py-2 border rounded ${
                        currentPage === pageNumber
                          ? "bg-[#961e38] text-white"
                          : "bg-white text-[#961e38]"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  );
                }
              )}

              {/* Next Button */}
              <button
                className={`px-4 py-2 border rounded ${
                  currentPage === totalPages
                    ? "cursor-not-allowed opacity-50"
                    : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {t("Next")}
              </button>
            </div>
            <div className="flex justify-center items-center gap-5">
              <p className="text-sm font-semibold">
                {t("Showing")} {itemsPerPage * (currentPage - 1) + 1} to{" "}
                {Math.min(
                  itemsPerPage * currentPage,
                  courseResp?.total_item_count || 0
                )}{" "}
                {t("of")} {courseResp?.total_item_count || 0} results
              </p>
              <div className="flex items-center gap-3">
                <p className="text-sm font-semibold">{t("Items per page")}:</p>
                <select
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(Number(e.target.value))}
                >
                  <option value={4}>4</option>
                  <option value={8}>8</option>
                  <option value={12}>12</option>
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingCost;
