import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CourseSessionItem,
  CourseSessionResponse,
} from "../../interfaces/sessions";
import { CourseItem } from "../../interfaces/courses";
import { AnyARecord } from "dns";
import { fetchDoceboOauthToken } from "../../utils/doceboAuth";

const initialState = {
  loading: false,
  sessionsData: [] as CourseSessionItem[],
  courseSessionResp: {} as CourseSessionResponse,
  selectedCourseData: {} as CourseItem,
  doceboSessionsData: {} as any,
};

interface QueryProps {
  page_size?: number;
  page?: number;
  sort_attr?: string;
  sort_order?: "asc" | "desc";
  filter_value?: string;
  course_id?: number;
}

export const getCourseSessions = createAsyncThunk(
  "sessions/getCourseSessions",
  async (props: QueryProps, { getState, rejectWithValue }) => {
    try {
      let query = "?";

      for (const [key, value] of Object.entries(props)) {
        query += `${key}=${value}&`;
      }

      const url = `${process.env.REACT_APP_BASE_API_URL}/sessions${query}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());

      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Authorization failed"
      );
    }
  }
);

export const updateSessionById = createAsyncThunk(
  "sessions/updateSessionById",
  async (
    props: {
      session_id: number;
      max_enrollments: number;
      min_enrollments: number;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      let query = `?session_id=${props.session_id}`;

      const url = `${process.env.REACT_APP_BASE_API_URL}/sessions${query}`;

      const body = {
        max_enrollments: props.max_enrollments,
        min_enrollments: props.min_enrollments,
      };

      const response = await fetch(url, {
        method: "PUT",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((res) => res.json());

      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Authorization failed"
      );
    }
  }
);

export const getDoceboSession = createAsyncThunk(
  "courses/getDoceboSession",
  async (session_id: any, { getState, rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("docebo_accessToken");
      const url = `https://devorbicollege.orbico.com/course/v1/sessions/${session_id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json());

      console.log("response===", response);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Authorization failed"
      );
    }
  }
);

const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    setSelectedCourseId: (state, action) => {
      state.selectedCourseData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseSessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourseSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionsData = action.payload?.data || [];
        state.courseSessionResp = action.payload;
      })
      .addCase(getCourseSessions.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDoceboSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDoceboSession.fulfilled, (state, action) => {
        state.loading = false;
        state.doceboSessionsData = action.payload;
      })
      .addCase(getDoceboSession.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default sessionsSlice.reducer;

export const { setSelectedCourseId } = sessionsSlice.actions;
