import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider, useDispatch } from "react-redux";
import App from "./App";
import "./index.css";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { initializeI18n } from "./i18n";
import { setInitialLanguage} from "./redux/slices/language.slice";

const LanguageInitializer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchLanguage = async () => {
            const userLanguage = await initializeI18n();
            dispatch(setInitialLanguage(userLanguage));
        };

        fetchLanguage();
    }, [dispatch]);

    return null; // No rendering visible, update only state
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <LanguageInitializer />
    <App />
  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
