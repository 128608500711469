import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../../components/Header";
import HomeButton from "../../../components/HomeButton";
import SpinnerLoader from "../../../components/SpinnerLoader";
import TabNavbar from "../../../components/Tabbar";

import { RootState, useAppDispatch } from "../../../redux/store";
import { HR_TABS } from "../../../utils/TabbarData";

import SearchWithFilter from "../../../components/SearchWithFilter";
import { useNavigate } from "react-router-dom";
import { getCourses } from "../../../redux/slices/hr.applications.slice";
import CourseCard from "../../../components/Card/CourseCard";
import { setSelectedCourseId } from "../../../redux/slices/hr.applications.slice";
import { getDoceboCourse } from "../../../redux/slices/couses.slice";
import { useTranslation } from "react-i18next";
const STATUS_MAP: any = {
  APPLIED: {
    label: "Manager Approval Pending",
    color: "bg-orange-500 text-white",
  },
  APPROVED_BY_MANAGER: {
    label: "Finding a Seat",
    color: "bg-blue-500 text-white",
  },
  WAITING: { label: "Waiting List", color: "bg-gray-500 text-white" },
  CONFIRMED_BY_LEARNER: {
    label: "Seat Confirmed",
    color: "bg-green-500 text-white",
  },
  IN_PROGRESS: {
    label: "Session in Progress",
    color: "bg-purple-500 text-white",
  },
  REJECTED_BY_MANAGER: {
    label: "Denied by manager",
    color: "bg-red-500 text-white",
  },
  REJECTED_BY_LEARNER: {
    label: "Denied by learner",
    color: "bg-red-500 text-white",
  },
  ENDED: { label: "Session Ended", color: "bg-gray-500 text-white" },
  REJECTED_BY_TOA: {
    label: "Denied by system",
    color: "bg-red-500 text-white",
  },
};

function AllTrainings() {
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { courseResp, courses, loading } = useSelector(
    (state: RootState) => state.hrApplications
  );

  const handlePageChange = (page: number) => setCurrentPage(page);
  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handleNavigateToCourseSessions = (id: number | string) => {
    const courseById = courses.find((course) => course.course_id === id);
    if (!courseById) return;
    dispatch(setSelectedCourseId(courseById));

    navigate("/hr/all-training/course-applications/" + id);
  };

  useEffect(() => {
    dispatch(
      getCourses({
        page_size: itemsPerPage,
        page: currentPage,
        filter_value: searchTerm, // Pass search term to API
      })
    );
  }, [currentPage, searchTerm, dispatch, itemsPerPage]);

  const totalPages = Math.ceil(
    (courseResp?.total_item_count || 0) / itemsPerPage
  );

  return (
    <div className="flex flex-col items-start gap-8">
      <HomeButton />
      <Header />
      <TabNavbar id="" tabs={HR_TABS} selectedTabId={1} />
      <div className="w-full h-full">
        <div className="w-full">
          <SearchWithFilter
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            disabled={loading}
          />
        </div>
        {loading ? (
          <div className="flex items-center w-full justify-center">
            <SpinnerLoader />
          </div>
        ) : (
          <div className="flex flex-col w-full justify-start items-start gap-8 px-10">
            {courses.map((item) => (
              <>
                <CourseCard
                  handleNavigateToCourseSessions={
                    handleNavigateToCourseSessions
                  }
                  item={item}
                />
              </>
            ))}
            {/* Pagination Controls */}
            {courses.length > 0 && !loading && (
              <div className="flex justify-between items-center w-full">
                <div className="flex justify-center items-center gap-4 mt-4">
                  {/* Previous Button */}
                  <button
                    className={`px-4 py-2 border rounded ${
                      currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    {t("Previous")}
                  </button>

                  {/* Dynamic Page Buttons */}
                  {Array.from({ length: Math.min(totalPages, 4) }).map(
                    (_, index) => {
                      const startPage = Math.max(
                        1,
                        Math.min(currentPage - 1, totalPages - 3)
                      );
                      const pageNumber = startPage + index;

                      return (
                        <button
                          key={pageNumber}
                          className={`px-4 py-2 border rounded ${
                            currentPage === pageNumber
                              ? "bg-[#961e38] text-white"
                              : "bg-white text-[#961e38]"
                          }`}
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      );
                    }
                  )}

                  {/* Next Button */}
                  <button
                    className={`px-4 py-2 border rounded ${
                      currentPage === totalPages
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    {t("Next")}
                  </button>
                </div>
                <div className="flex justify-center items-center gap-5">
                  <p className="text-sm font-semibold">
                    {t("Showing")} {itemsPerPage * (currentPage - 1) + 1}{" "}
                    {t("to")}{" "}
                    {Math.min(
                      itemsPerPage * currentPage,
                      courseResp?.total_item_count || 0
                    )}{" "}
                    {t("of")} {courseResp?.total_item_count || 0} {t("results")}
                  </p>
                  <div className="flex items-center gap-3">
                    <p className="text-sm font-semibold">
                      {t("Items per page")}:
                    </p>
                    <select
                      value={itemsPerPage}
                      onChange={(e) => setItemsPerPage(Number(e.target.value))}
                    >
                      <option value={4}>4</option>
                      <option value={8}>8</option>
                      <option value={12}>12</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AllTrainings;
