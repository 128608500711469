import {
  MdApproval,
  MdOutlineEventSeat,
  MdOutlineMoney,
  MdPerson,
} from "react-icons/md";
import { CiClock2 } from "react-icons/ci";
import { BsCalendar2WeekFill } from "react-icons/bs";
import { FaRegFolder } from "react-icons/fa6";
import { MdManageHistory } from "react-icons/md";
import { useTranslation } from "react-i18next";

export const ADMIN_TABS = [
  {
    id: 1,
    icon: <MdOutlineEventSeat color="#752938" size={20} />,
    title: "Seat Allocation",
    url: "/courses",
  },
  {
    id: 2,
    icon: <MdOutlineMoney color="#752938" size={20} />,
    title: "Training Cost",
    url: "/admin/training-cost",
  },
];

export const MANAGER_TABS = [
  {
    id: 1,
    icon: <MdApproval color="#752938" size={20} />,
    title: "Manager Approvals",
    url: "#",
  },
];
export const HR_TABS = [
  {
    id: 1,
    icon: <MdApproval color="#752938" size={20} />,
    title: "Hr Approvals",
    url: "/hr/all-trainings",
  },
  {
    id: 4,
    icon: <MdPerson color="#752938" size={20} />,
    title: "User Applications",
    url: "/hr/user-applications",
  },
];

export const LEARNER_TABS = [
  {
    id: 1,
    icon: <CiClock2 color="#752938" size={20} />,
    title: "Available Trainings",
    url: "/learner/available-trainings",
  },
  {
    id: 2,
    icon: <BsCalendar2WeekFill color="#752938" size={20} />,
    title: "Application Status",
    url: "/learner/applied-trainings",
  },
  {
    id: 3,
    icon: <MdManageHistory color="#752938" size={20} />,
    title: "My Training History",
    // url: "/learner/my-trainings-history",
    url: `${process.env.REACT_APP_LEARNING_HISTORY}`,
  },
  // {
  //   id: 3,
  //   icon: <FaRegFolder color="#752938" size={20} />,
  //   title: "ADVANCED TRAININGS",
  //   url: "#",
  // },
];

export interface Tabs {
  id: number;
  icon: any;
  title: string;
  url: string;
}
