import React from "react";
import LanguageSelector from "../LanguageSelector";
import { useTranslation } from "react-i18next";

function Header() {
  const username = sessionStorage.getItem("username");
  const { t } = useTranslation();

  const role = sessionStorage.getItem("role");

  return (
    <div className="flex w-full justify-between items-center">
      <div className="flex items-center justify-between gap-5">
        <div className="flex flex-col justify-start items-start w-full">
          <p className="text-2xl font-semibold text-[#ac112d]">
            {t("Welcome")}
          </p>
          <p className="text-lg font-light text-[#ac112d]">
            {username || `Dean JEANE`}
          </p>
        </div>
      </div>
      <div>
        {role && (
          <p className="text-lg font-semibold text-[#ac112d] flex items-center min-w-[180px]">
            {t("Logged In")} as {t(role)}
          </p>
        )}
        <LanguageSelector />
      </div>
    </div>
  );
}

export default Header;
