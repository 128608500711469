import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/slices/language.slice";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/store";

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector((state: RootState) => state.language.lang);

  const handleChange = (e: any) => {
    dispatch(setLanguage(e.target.value));
  };

  return (
    <div className="p-4 flex items-center">
      <label className="text-gray-700">{t("Language")}:</label>
      <select
        value={language}
        onChange={handleChange}
        className="ml-2 p-2 border rounded"
      >
        <option value="en">English</option>
        <option value="hr">Croatian</option>
        <option value="sl">Slovenian</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
