import { createSlice } from "@reduxjs/toolkit";
import i18n from "../../i18n";

const updateUserLanguage = async (language: string) => {
  try {
    const url = `${process.env.REACT_APP_BASE_API_URL}/language`;

    await fetch(url, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify( { "language": language } ),
    });
  } catch (error) {
    console.error("Failed to update user language:", error);
  }
};

const languageSlice = createSlice({
  name: "language",
  initialState: { lang: "en" },
  reducers: {
    setLanguage: (state, action) => {
      state.lang = action.payload;
      i18n.changeLanguage(action.payload);
      updateUserLanguage(action.payload);
    },
    setInitialLanguage: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { setLanguage, setInitialLanguage } = languageSlice.actions;
export default languageSlice.reducer;
