import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import hr from "./locales/croatian.json";
import sl from "./locales/slovenian.json";
import en from "./locales/english.json";

const fetchUserLanguage = async () => {
  try {
    const url = `${process.env.REACT_APP_BASE_API_URL}/language`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.language;
    }
  } catch (error) {
    console.error("Failed to fetch user language:", error);
  }
  return "en"; // Default language
};

export const initializeI18n = async () => {
  const userLanguage = await fetchUserLanguage();

  i18n.use(initReactI18next).init({
    resources: {
      hr: { translation: hr },
      sl: { translation: sl },
      en: { translation: en },
    },
    lng: userLanguage,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

  return userLanguage;
};

export default i18n;
